<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="account—settings">
            <el-col :span="3" style="width:15.3%;"></el-col>
            <el-col :span="17">
            <div class="settings_box">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>订单中心</el-breadcrumb-item>
                <el-breadcrumb-item>批量下单</el-breadcrumb-item>
                </el-breadcrumb>
                <div style="display:flex;">
                <div class="box_left">
                    <LeftHurdle/>
                </div>
                <div class="box_rights">
                    <div class="box_right_top">批量下单</div>
                    <div class="box_right_cent">
                        <div class="cent_Bulk">
                            <div class="flex-between">
                                <div>批量下单详情</div>
                                <div class="bulk-gointo" @click="uploadflie = true">导入批量下单模板</div>
                            </div>
                        </div>
                        <div class="Bulk-cent">
                            <el-table
                            :data="tableData"
                            border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                            :cell-style="{ textAlign: 'center' }"
                            style="width: 100%;text-align: center;">
                                <el-table-column
                                prop="skuId"
                                label="SKUID">
                                </el-table-column>
                                <el-table-column
                                prop="goodsName"
                                label="商品名称" width="200">
                                </el-table-column>
                                <el-table-column
                                v-if="isExternal == 2"
                                prop="externalCorporateName"
                                label="外部企业名称" width="200">
                                </el-table-column>
                                <el-table-column
                                prop="skuValues"
                                label="规格">
                                </el-table-column>
                                <el-table-column
                                prop="num"
                                label="数量" width="80">
                                </el-table-column>
                                <el-table-column
                                prop="receiveName"
                                label="收件人">
                                </el-table-column>
                                <el-table-column
                                prop="receivePhone"
                                label="联系电话">
                                </el-table-column>
                                <el-table-column
                                prop="address"
                                label="收货地址"  width="200">
                                </el-table-column>
                                <el-table-column
                                prop="what"
                                label="操作" width="100">
                                <template slot-scope="scope">
                                <div @click="amend(scope.$index,scope.row)" style="color:#02A7F0">
                                    修改
                                </div>
                                </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <div class="flex-start">
                                <div class="bulk_consignee">发票信息</div>
                                <div class="bulk_remind">
                                    发票信息会在集中开票时自动带入，集中开票时也可修改，请放心选择
                                </div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">开票类型</div>
                                <div>增值税专用发票</div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">开票方式</div>
                                <div>集中开票</div>
                            </div>
                            <div class="flex-start cent_box">
                                <div class="cent_text">发票内容</div>
                                <div>明细</div>
                            </div>
                            <div class="flex-start cent_box cent_bulk">
                                <div>{{ invoiceHeader }} {{ taxpayerNo }}</div>
                                <div v-show="invoice==1"><div @click="goinvoice()" style="color:#cd9f49;margin:10px 0;">添加发票</div></div>
                            </div>
                            <div class="bulk_select" @click="getrestInvoice()">
                                <div class="bulk_select-text">选择其他发票V</div>
                                <div class="" v-if="showed">
                                    <div class="bulk_select_cent">
                                        <el-table
                                            :data="invoicedata"
                                            border
                                            :header-cell-style="{
                                                background: '#cccccc',
                                                color: '#333',
                                                textAlign: 'center',
                                            }"
                                            :cell-style="{
                                                textAlign: 'center',
                                            }"
                                            style="
                                                width: 100%;
                                                text-align: center;
                                            "
                                            @current-change="
                                                handleSelectionChange
                                            "
                                            highlight-current-row
                                        >
                                            <el-table-column label="选择" width="70" center >
                                                <template slot-scope="scope">
                                                    <div @click.stop.prevent="getCurrentRow(scope.$index,scope.row)">
                                                        <el-radio class="radio" v-model="radio" :label="scope.$index">&nbsp;</el-radio>
                                                    </div>
                                                    
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="invoiceHeader"
                                                label="名称"
                                                width="200"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="taxpayerNo"
                                                label="纳税人识别号"
                                                width="230"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="registeredAddress"
                                                label="地址电话"
                                            >
                                                <template slot-scope="scope">
                                                    <div>{{scope.row.registeredAddress}}</div>
                                                    <div>{{scope.row.registeredPhone}}</div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="bankAccount"
                                                label="开户行及账号"
                                                width="250"
                                            >
                                                <template slot-scope="scope">
                                                    <div>{{scope.row.bankName}}</div>
                                                    <div>{{scope.row.bankAccount}}</div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:30px">
                            <div class="flex-start">
                                <div class="bulk_consignee">下单项目</div>
                                <div class="bulk_remind">
                                    订单提交后下单项目不可修改，请您下单前再三核实
                                </div>
                            </div>
                            <div class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text">订单归属</div>
                                <div>
                                    <el-radio-group v-model="radios" @change="toggleTab()">
                                        <el-radio :label="item.value" :key="item.value" v-for="item in radio1" >{{item.label}}</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text">下单组织</div>
                                <div>
                                   <el-select v-model="place" placeholder="请选择">
                                       <el-option
                                        v-for="item in placeOptions"
                                        :key="item.manageId"
                                        :label="item.manageName"
                                        :value="item.manageId">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div v-if="!$store.state.externalId" class="flex-start cent_box" style="margin-top:20px">
                                <div class="cent_text" style="margin-right: 22px;">发票收件邮箱</div>
                                <div>
                                    <el-autocomplete
                                    class="inline-input"
                                    v-model="email"
                                    clearable
                                    :fetch-suggestions="filterMethod"
                                    placeholder="请输入邮箱地址"
                                    @select="handleEmailSelect"
                                    ></el-autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="cent_goods">
                            <div class="goods_text flex-between">
                                <div>商品清单</div>
                            </div>
                            <el-table
                                :data="checklist"
                                border
                                :header-cell-style="{
                                    background: '#cccccc',
                                    color: '#333',
                                    textAlign: 'center',
                                }"
                                :cell-style="{ textAlign: 'center' }"
                                style="width: 100%; text-align: center"
                            >
                                <el-table-column
                                    prop="infor"
                                    label="商品信息"
                                    width="500"
                                >
                                    <template slot-scope="{ row }">
                                        <div v-if="Array.isArray(row.infor)">
                                            <div
                                                v-for="(
                                                    adr, index
                                                ) in row.infor"
                                                :key="index"
                                                class="rowBox flex-center"
                                            >
                                                <img :src="adr.img" alt="" />
                                                <p>{{ adr.name }}</p>
                                            </div>
                                        </div>
                                        <span v-else>{{ row.infor }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="skuValue"
                                    label="颜色尺码"
                                >
                                    <template slot-scope="{ row }">
                                        <div v-if="Array.isArray(row.skuValue)">
                                            <p
                                                v-for="(adr, index) in row.skuValue"
                                                :key="index"
                                            >
                                                {{ adr }}
                                            </p>
                                        </div>
                                        <span v-else>{{ row.skuValue }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="goodsPrice"
                                    label="单价"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="nums"
                                    label="数量"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="allGoodsPrice"
                                    label="小计"
                                >
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="cent_purchase">
                            <div class="goods_text">采购信息</div>
                            <div class="flex-start purchase">
                                <div>订单备注</div>
                                <div class="purchase_text">
                                    订单备注信息仅作为您自己浏览时的备注信息，并不会向商家.快递人员展示
                                </div>
                            </div>
                            <div>
                                <el-input
                                    type="textarea"
                                    v-model="textarea"
                                    maxlength="100"
                                    placeholder="对本次采购进行备注，以便于之后查看或导出。请勿填写有关支付、收货、发票方面信息"
                                    :rows="1"
                                ></el-input>
                            </div>
                        </div>
                        <div class="flex-end">
                            <div class="cent_how">
                                <div>
                                    共{{ goodsTypeNums }}件商品，订单总金额：¥{{
                                        payAmount
                                    }}
                                </div>
                                <div class="flex-end freight">
                                    运费：¥{{ freight }}
                                </div>
                            </div>
                        </div>
                        <div class="cent-remind">
                            <div class="remind_text">订单注意事项</div>
                            <div>
                                订单提交后将进行审核，商品预计送达日期为无需审批的最快时效，实际配送将在订单审批和支付完成后执行。
                            </div>
                            <div>
                                订单中存在不支持7天无理由退货商品，请确认相关商品信息后提交订单。
                            </div>
                            <div>订单中存在京东慧采商品，请在7天内完成支付，否则系统将自动取消订单。</div>
                        </div>
                        <div class="flex-end">
                            <div class="cent_inall">
                                <div class="flex-end">
                                    应付总额：<span>¥{{ totalAmount }}</span>
                                </div>
                                <div class="flex-end">
                                    <div class="inall_btn" @click="throttle(null, 1000)">
                                        提交订单
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <!-- 修改收货信息 -->
                <el-dialog
                    title="修改收货信息"
                    :visible.sync="dialogVisible"
                    width="30%">
                    <el-form ref="dialogForm" :model="dialogForm" :rules="dataForm">
                        <el-form-item prop="userName" label="收货人:">
                            <el-input
                                v-model="dialogForm.userName"
                                placeholder="请输入收货人姓名"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="number" label="联系电话:">
                            <el-input
                                v-model="dialogForm.number"
                                placeholder="请填写收货人的手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="address" label="收货地址:">
                            <el-input
                                v-model="dialogForm.address"
                                placeholder="请填写收货地址"
                            ></el-input>
                        </el-form-item>
                        <el-form-item style="padding-left: 60px">
                            <el-button
                                type="warning"
                                class="register-btn"
                                @click="giveAddress(dialogForm.id)"
                                >确定</el-button
                            >
                            <el-button
                                class="register-btn"
                                @click="dialogVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-form>
                </el-dialog>
                <!-- 导入下单模板 -->
                <el-dialog title="导入批量下单" width="40%" :visible.sync="uploadflie" :modal-append-to-body="false" class="tbdialog undraggablebox">
                    <div style="margin-left: 50px;">
                    <span>上传数据：</span>
                    <el-upload style="display: inline"
                                class="upload-demo"
                                action="#"
                                :file-list="fileList"
                                :before-upload="beforeUpload"
                                :on-remove="handleRemove"
                                :on-change="getValChange"
                                :http-request="handlePictureSuccess">
                        <el-button type="text">选择文件</el-button>
                    </el-upload>
                    </div>
                    <span slot="footer" class="dialog-footer">
                    <el-button type="primary" :disabled="uploadfile===undefined" :loading="importBtn" @click="handleUpload">确 定</el-button>
                    </span>
                </el-dialog>
                <!-- 报错 -->
                <el-dialog title="不可购买商品" :visible.sync="dialogTableVisible">
                    <el-table :data="gridData" border :height="gridData.length>5 ? 312 :''">
                        <el-table-column property="skuId" label="skuId" width="100"></el-table-column>
                        <el-table-column property="notBuyGoodsName" label="商品名称"></el-table-column>
                        <el-table-column property="notBuyType" label="不可以购买原因" width="250"></el-table-column>
                    </el-table>
                </el-dialog>
                <!-- 报错 -->
                <el-dialog title="不可购买商品" :visible.sync="dialogTable">
                    <el-table :data="notgoodsData" border :height="notgoodsData.length>5 ? 312 :''"> 
                        <el-table-column property="notBuyGoodsName" label="商品名称"></el-table-column>
                        <el-table-column property="notBuyType" label="不可以购买原因" width="250"></el-table-column>
                    </el-table>
                </el-dialog>
                <!-- 报错 -->
                <el-dialog title="导入数据错误提示" :visible.sync="dialogData">
                    <el-table :data="notListData" border> 
                        <el-table-column property="errorMsg" label="错误原因">
                            <template slot-scope="scope">
                                <div>第{{scope.row.rowNum}}行：{{scope.row.errorMsg}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-dialog>
                <el-dialog
                    custom-class="custom"
                    :visible.sync="oderTishi"
                    width="20%"
                    :close-on-click-modal="false"
                    :show-close="false">
                    <span>该订单正在提交中，请耐心等待</span>
                </el-dialog>
            </div>
            
            </el-col>
            <el-col :span="3" style="width:15.5%;"></el-col>
        </el-row>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'

import {
    getInvoice,
    getrestInvoice,
    getBatchList,
    goodsInfoList,
    importBatchOrder,
    updateBatchOAddress,
    confirmBatchOrderInfo,
    batchSubmitOrder,
    getOrderAscription,
    getInvoiceEmail
} from "@/api/order/order";
import { phoneReg } from '@/utils/regular'
export default {
    components: { Header,Footer,LeftHurdle },
    data () {
        return{
            number:'4',
            tableData: [],
            isExternal: 1,
            dialogVisible:false,
            dialogForm: {
                userName: "",
                number: "",
                address: "",
                id:''
            },
            //发票信息
            taxpayerNo: "",
            invoiceHeader: "",
            invoice:0,
            showed: false,
            invoicedata: [],
            radio: '',
            invoiceRecordId: "",
            //商品清单
            checklist:[],
            goodsTypeNums:'',
            freight: "",
            payAmount: "",
            totalAmount: "",
            textarea: "",//备注
            batchNo:111111,
            dataForm: {
                userName: [
                    { required: true, message: '收货人不能为空', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: '手机号不能为空', trigger: 'blur' },
                    { pattern: phoneReg, message: "请输入正确的手机号码"}
                ],
                address: [
                    { required: true, message: '收货地址不能为空', trigger: 'blur' }
                ]
            },
            //上传的文件
            uploadfile: undefined,
            uploadflie:false,
            importBtn:false,
            dialogTableVisible:false,
            gridData:[],
            prev: 0,
            dialogTable:false,
            notgoodsData:[],
            fileList:[],
            dialogData:false,
            notListData:[],
            oderTishi:false,
            radios:'1',
            radio1:[{value:"1",label:'为公司下单'},{value:"2",label:'为项目下单'}],
            placeOptions: [],
            place: '',
            email: '',
            emailList: [],
            emailLoading: false,
        }
    },
    created(){
        this.getInvoice()
        this.getOrderAscription(this.radios)
        if(!this.$store.state.externalId) {
            this.getEmail()
        }
    },
    methods:{
        getEmail() {
            this.emailLoading = true;
            getInvoiceEmail({}).then(res => {
                if(res.code == '00000') {
                    this.emailList = res.data.map(item => ({
                        label: item,
                        value: item
                    }))
                }
            }).finally(() => this.emailLoading = false)
        },
        // 邮箱搜索
        filterMethod(query, cb) {
            if (query !== '') {
                let data = this.emailList.filter(item => {
                    return item.value.indexOf(query) > -1;
                });
                cb(data)
            } else {
                cb(this.emailList)
            }
        },
        handleEmailSelect(item){
            this.email = item.value
        },
        //修改收货信息
        amend(index,row){
            console.log(index,row)
            this.dialogVisible=true
            this.dialogForm.userName=row.receiveName
            this.dialogForm.number=row.receivePhone
            this.dialogForm.address=row.address
            this.dialogForm.id=row.id
        },
        //确认收货信息
        giveAddress(){
            this.$refs['dialogForm'].validate((valid) => {
                 if(valid){
                     const data = {
                        "address": this.dialogForm.address,
                        "id": this.dialogForm.id,
                        "receiveName": this.dialogForm.userName,
                        "receivePhone": this.dialogForm.number
                    }
                    updateBatchOAddress(data).then(data=>{
                        if(data&&data.code=='00000'){
                            this.$message({
                                message: "修改成功",
                                type: "success",
                            });
                            this.dialogVisible=false
                            this.getBatchList(this.batchNo)
                            this.confirmBatchOrderInfo(this.batchNo)
                            
                        }
                    })
                 }
            })
        },
        goinvoice(){
            this.$router.push({ name: 'AddedQualification'})
        },
        //获取发票
        getInvoice() {
            let data = {
                id: "",
                name: "",
            };
            getInvoice(data).then((data) => {
                if (data && data.code == "00000") {
                    if(data.data==null){
                        this.invoice=1
                    }else{
                        this.invoiceHeader = data.data.invoiceHeader;
                        this.taxpayerNo = data.data.taxpayerNo;
                        this.invoiceRecordId = data.data.id;
                    }
                    
                }
            });
        },
        //获取其它发票
        getrestInvoice() {
            this.showed = !this.showed;
            let data = {
                id: "",
                name: "",
            };
            getrestInvoice(data).then((data) => {
                if (data && data.code == "00000") {
                    this.invoicedata = data.data;
                    this.invoicedata.forEach((item,index)=>{
                        if(item.id ===this.invoiceRecordId){
                            this.radio=index
                        }
                    })
                    
                }
            });
        },
        getCurrentRow(index,val){
            console.log(index,val)
            this.radio=index
            this.invoiceHeader = val.invoiceHeader;
            this.taxpayerNo = val.taxpayerNo;
            this.invoiceRecordId = val.id;
        },
        handleSelectionChange(val) {
            this.invoiceHeader =val.invoiceHeader;
            this.taxpayerNo = val.taxpayerNo;
            this.invoiceRecordId = val.id;
        },
        //查询批量下单导入的下单数据
        getBatchList(batchNo){
            let data={
                "batchNo": batchNo,
            }
            getBatchList(data).then((data) => {
                if(data&&data.code=='00000'){
                    this.isExternal = data.data.type
                    this.tableData=data.data.batchList
                }
            })
        },
        //查询下单的商品清单信息
        goodsInfoList(batchNo){
            let data={
                "batchNo": batchNo,
            }
            goodsInfoList(data).then((data) => {
                if(data&&data.code=='00000'){
                    this.checklist=[]
                    data.data.forEach((item)=>{
                        let obj = {};
                        let objs = {};
                        let infor = [];
                        objs.img = item.goodsPic;
                        objs.name = item.goodsName;
                        infor.push(objs);
                        obj.infor = infor;
                        obj.skuValue = item.skuValue;
                        obj.goodsPrice = item.goodsPrice;
                        obj.nums = item.nums;
                        obj.allGoodsPrice = (item.allGoodsPrice).toFixed(2);
                        this.checklist.push(obj);
                    })
                    
                }
            })
        },
        // 上传文件前 先将文件存起来
        beforeUpload(file){
            this.uploadfile = file
        },
        //删除文件
        handleRemove () {
            this.uploadfile = undefined;
        },
        handlePictureSuccess() {},
        getValChange(file,fileList){
            if (fileList.length > 0) {
                this.fileList = [fileList[fileList.length - 1]] 
            }
        },
        //上传文件
        handleUpload(){
            this.importBtn = true;
            let formData = new FormData();
            formData.append('file', this.uploadfile)
            importBatchOrder(formData).then((data)=>{
                this.importBtn = false;
                if(data&&data.code =='00000'){
                    this.uploadflie=false
                    let arr=data.data.split("||");
                    this.batchNo=arr[0]
                    let number =arr[1]
                    this.$message({
                        message: "成功导入"+number+"数据",
                        type: "success",
                    });
                    this.getBatchList(this.batchNo)
                    this.goodsInfoList(this.batchNo)
                    this.confirmBatchOrderInfo(this.batchNo)
                    this.handleRemove()
                }else if(data.code=='E0002'){
                    let datas = data.desc
                    let list = JSON.parse(datas)
                    if(list.length>1){
                        this.dialogData=true
                        this.notListData=list
                    }else{
                        list.forEach(item=>{
                            this.$notify.error({
                                title: '错误',
                                message:"原因:第"+item.rowNum+"行"+item.errorMsg,
                            });
                        })
                        
                    }
                    
                }
            })
        },
        //下单确认订单价格计算以及可售性判断
        confirmBatchOrderInfo(batchNo){
            let data={
                "batchNo": batchNo,
            }
            confirmBatchOrderInfo(data).then((data) => {
                if(data&&data.code=='00000'){
                    if (data.data.isContinueBuy == true) {
                        this.payAmount = (data.data.payAmount).toFixed(2);
                        this.freight = data.data.freight;
                        this.totalAmount = (data.data.totalAmount).toFixed(2);
                        this.goodsTypeNums = data.data.goodsTypeNums;
                        // console.log(this.payAmount,999)
                    }else{
                        let notBuyType=''
                        if(data.data.notBuyGoodsDTOList.length>1){
                            this.dialogTableVisible=true
                            this.gridData=data.data.notBuyGoodsDTOList
                            this.gridData.forEach((item)=>{
                                if(item.notBuyType==1){
                                    item.notBuyType='商品下架'
                                }else if(item.notBuyType==2){
                                    item.notBuyType='商品库存不足 '
                                }else if(item.notBuyType==3){
                                    item.notBuyType='商品价格异常 '
                                }else if(item.notBuyType==4){
                                    item.notBuyType='商品配送地区限制 '
                                }else if(item.notBuyType==5){
                                    item.notBuyType='商品超过限购数量 '
                                }else if(item.notBuyType==6){
                                    item.notBuyType='福利卡商品与普通商品无法一起购买 '
                                }else if(item.notBuyType==19){
                                    item.notBuyType='怡亚通商品不可售 '
                                }else if(item.notBuyType==21){
                                    item.notBuyType='地址怡亚通无法识别 '
                                }else if(item.notBuyType==22){
                                    item.notBuyType='批量下单不支持福利卡商品 '
                                }
                            })
                        }else{
                            data.data.notBuyGoodsDTOList.forEach((item)=>{
                                if(item.notBuyType==1){
                                    notBuyType='商品下架'
                                }else if(item.notBuyType==2){
                                    notBuyType='商品库存不足 '
                                }else if(item.notBuyType==3){
                                    notBuyType='商品价格异常 '
                                }else if(item.notBuyType==4){
                                    notBuyType='商品配送地区限制 '
                                }else if(item.notBuyType==5){
                                    notBuyType='商品超过限购数量 '
                                }else if(item.notBuyType==6){
                                    notBuyType='福利卡商品与普通商品无法一起购买 '
                                }else if(item.notBuyType==19){
                                    notBuyType='怡亚通商品不可售 '
                                }else if(item.notBuyType==21){
                                    notBuyType='地址怡亚通无法识别 '
                                }else if(item.notBuyType==22){
                                    item.notBuyType='批量下单不支持福利卡商品 '
                                }
                                this.$notify.error({
                                    title: '错误',
                                    message: "商品名称:"+item.notBuyGoodsName,
                                });
                                this.$nextTick(() => {
                                    this.$notify.error({
                                        title: '错误',
                                        message:"不可购买原因:"+notBuyType,
                                    });
                                })
                            })
                        }
                    }
                }
            })
        },
        throttle(fn, delay) {
            var now = Date.now();
            if (now - this.prev > delay) {
                this.inallBtn();
                this.prev = Date.now();
            }
        },
        //提交订单
        inallBtn() {
            if(this.invoiceRecordId==''){
                this.$message.error('发票没有选择');
            }else if(this.ascriptionId==''){
                this.$message.error('请选择为公司或者为项目下单');
            }else if(!this.$store.state.externalId && !this.email){
                this.$message.error('请输入邮箱地址');
            }else{
                let data = {
                    batchNo:this.batchNo,
                    invoiceRecordId: parseInt(this.invoiceRecordId),
                    ascriptionId: this.place,
                    ascriptionType: this.radios,
                    receivingType: 0,
                    remark:this.textarea,
                };
                if(!this.$store.state.externalId){
                    data.invoiceEmail = this.email
                }
                this.oderTishi=true
                batchSubmitOrder(data).then((data) => {
                    if(data.code=='00000'){
                        if(data.data.isSuccess==true){
                            this.$message({
                                message: '订单提交成功',
                                type: 'success'
                            });
                            this.$router.push({ name: 'MyOrder'})
                            
                        }else{
                            if(data.data.failGoodsNameList!=null){
                                if(data.data.failGoodsNameList.length>1){
                                    this.dialogTable=true
                                    this.notgoodsData=[]
                                    data.data.failGoodsNameList.forEach(item=>{
                                        let obj={}
                                        obj.notBuyGoodsName=item
                                        obj.notBuyType=data.data.msg
                                        this.notgoodsData.push(obj)
                                    })
                                }else{
                                    data.data.failGoodsNameList.forEach((item)=>{
                                        this.$nextTick(() => {
                                            this.$notify.error({
                                                title: '错误',
                                                message:"原因:"+data.data.msg+','+'名称:'+item,
                                            });
                                        })
                                    
                                    })
                                }
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: data.data.msg
                                });
                            }

                            if(data.data.failAddressList!=null){
                                data.data.failAddressList.forEach((item)=>{
                                    this.$nextTick(() => {
                                        this.$notify.error({
                                            title: '错误',
                                            duration: 0,
                                            message:"原因:"+data.data.msg+','+'名称:'+item,
                                        });
                                    })
                                
                                })
                            }
                            
                        }
                        
                    }
                    
                }).catch(() => {
                    this.$notify.error({
                        title: '错误',
                        duration: 10000,
                        message:"原因:网络异常，请刷新页面重新提交。(注：如果多次刷新还是无法提交订单，请联系管理员或技术人员。)",
                    });
                }).finally(() => {
                    this.oderTishi = false
                })
                
            }
        },
        getOrderAscription(radios){
            let res ={
                ascriptionType:radios
            }
            getOrderAscription(res).then((data)=>{
                if(data &&data.code=="00000"){
                    this.placeOptions=data.data
                }
            })
        },
        toggleTab(){
            this.getOrderAscription(this.radios)
        }
    }
}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .box_right_cent{
        margin: 20px 15px 0;
        .cent_Bulk{
            width: 100%;
            font-size: 17px;
            margin-bottom: 10px;
            .bulk-gointo{
                font-size: 13px;
                width: 130px;
                height: 28px;
                line-height: 28px;
                background: #cd9f49;
                color: #Fff;
                border-radius: 4px;
                text-align: center;
            }
        }
        .Bulk-cent{
            margin-bottom: 20px;
        }
        .bulk_consignee {
            font-size: 20px;
            margin-right: 40px;
        }
        .bulk_remind {
            background: #f2f2f2;
            font-size: 14px;
            padding: 10px 20px;
        }
        .cent_box {
            margin: 10px 0;
            font-size: 14px;
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #cd9f49;
                background: #cd9f49;
            }
            .el-radio__input.is-checked+.el-radio__label{
                color:black
            }
        }
        .cent_text {
            margin-right: 50px;
        }
        .cent_bulk {
            margin: 10px 0 10px 30px;
        }
        .bulk_select {
            .bulk_text {
                color: #cd9f49;
            }
            .bulk_select-text{
                color: #cd9f49;
                cursor: pointer;
            }
            .select-box {
                .box-bulk {
                    margin: 15px 0;
                    .bulk-box {
                        background: rgba(0, 0, 0, 0.03);
                        width: 194px;
                        height: 90px;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.85);
                        padding: 35px 19px 15px 15px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        .el-radio__input.is-checked .el-radio__inner {
                            border-color: #cd9f49;
                            background: #cd9f49;
                        }
                        .detail_num {
                            color: rgba(0, 0, 0, 0.45);
                            margin-top: 5px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .cent_goods {
            margin: 40px 0;
        }
        .goods_text {
            font-size: 20px;
            margin-bottom: 20px;
        }
        .rowBox {
            img {
                width: 60px;
                height: 60px;
                margin-right: 15px;
            }
        }
        .cent_purchase {
            margin-top: 70px;
            border-bottom: 1px solid #d7d7d7;
            padding-bottom: 25px;
        }
        .purchase {
            font-size: 14px;
            color: #333;
            padding-bottom: 15px;
        }
        .purchase_text {
            font-size: 12px;
            color: #aeaeae;
            margin-left: 20px;
        }
        .cent_how {
            margin: 20px 0;
        }
        .freight {
            margin-top: 10px;
        }
        .cent-remind {
            font-size: 14px;
            border: 1px solid #cd9f49;
            padding: 10px 15px;
        }
        .remind_text {
            margin-bottom: 10px;
        }
        .cent_inall {
            margin: 20px 0;
            font-size: 14px;
            span {
                color: red;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .inall_btn {
                margin-top: 15px;
                width: 198px;
                height: 62px;
                line-height: 62px;
                text-align: center;
                background: #cd9f49;
                color: #fff;
                border-radius: 10px;
                font-size: 18px;
            }
            
            
        }
    }
    
  }
  .el-form-item {
        .el-form-item__label {
            width: 83px;
        }
        .el-button {
            width: 70px;
            text-align: center;
            padding: 0;
        }
        .el-cascader {
            width: 100%;
        }
    }
    .custom{
        .el-dialog__header{
            padding: 0;
        }
    }
}
</style>